import { api, ceping, order } from '@/api'
import { config } from '@/utils'
import wx from 'weixin-js-sdk';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
export default {
    name: 'Detail',

    created () {

        // 判断是否有id
        if (!this.$route.query.id) {
            this.$toast('缺少id参数')
            return setTimeout(() => { this.$router.push({ name: 'Default' }) }, 500);
        }

        // 获取数据
        this.handleGetData()
    },

    watch: {
        '$route' (v, o) {
            if (v.query.id == o.query.id) return
            this.handleGetData()
        }
    },

    methods: {
        // 获取数据
        handleGetData() {
            this.$toast.loading({ message: '加载中...', forbidClick: true })
            let options = {
                id: this.$route.query.id
            };
            if (this.$route.query.cp) options.cp = this.$route.query.cp
            ceping.detail(options).then(res => {
                this.$toast.clear()
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message)
                this.loading = false
                document.title = res.data.title
                this.item = res.data
                this.ticketUrl = res.data.ticket
                this.handleGetComment()
                // 分享配置
                this.handleShare(res.data)
            }).catch(err => {
                this.$toast.clear()
                this.isAjax = false
                console.log(err)
            })
        },

        // 分享
        async handleShare (item) {
            // console.log(location.href.split('#')[0], process.env.VUE_APP_DEBUG == 1 ? true : false)
            // if (!_global.wx || process.env.VUE_APP_CONFIG != 'test') return
            if (!_global.wx) return
            let url = location.href.split('#')[0];
            let res = await api.share({url: encodeURIComponent(url)})
            wx.config({
                debug: process.env.VUE_APP_DEBUG == 1 ? true : false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.appId, 
                timestamp: res.timestamp, 
                nonceStr: res.nonceStr, 
                signature: res.signature,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] 
            });
             
            wx.ready(function () {
                // 分享好友
                wx.updateAppMessageShareData({
                    title: item.title,                                  // 分享标题
                    desc: item.title2,
                    link: url,                      // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致且分享的地址不要带端口
                    imgUrl: item.listimg,                               // 分享图标
                    success: function (e) {
                      console.log('分享好友设置成功')
                    }
                });
                
                // 分享朋友圈
                wx.updateTimelineShareData({ 
                    title: item.title,                                      // 分享标题
                    link: url,                          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致且分享的地址不要带端口
                    imgUrl: item.listimg,                                   // 分享图标
                    success: function (e) {
                        console.log('分享朋友圈设置成功')
                    }
                });
            })
        },

        // 获取评论
        handleGetComment () {
            ceping.comment({
                goodsid: this.$route.query.id
            }).then(res => {
                if (res.status != 1) return this.$toast(res.message)
                this.commentItems = res.data
            }).catch(err => {
                console.log(err)
            })
        },

        // 获取客户二维码
        handleQrcode () {
            if (this.qrcodeUrl) return this.isQrcode = true
            if (this.isAjax) return
            this.isAjax = true
            api.qrcode().then(res => {
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message)
                this.qrcodeUrl = res.data
                this.isQrcode = true
            }).catch(err => {
                this.isAjax = false
                console.log(err)
            })
        },

        // 创建订单
        handleOrderAdd () {
            // 有ticketUrl 则弹窗二维码下单
            if (this.ticketUrl != '') return this.handleCreateQrcode()
            this.handleCreateOrder()
        },
        // 二维码创建订单
        handleCreateQrcode () {
            this.isTicket = true
        },
        // 支付创建订单
        handleCreateOrder () {
            if (this.isAjax) return
            this.$toast.loading({ message: '订单创建中', forbidClick: true, loadingType: 'spinner' });
            this.isAjax = true
            order.add({
                goodsid: this.item.id
            }).then(res => {
                this.$toast.clear()
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message)
                this.handleOrderShow(res.data.sn)
            }).catch(err => {
                this.$toast.clear()
                this.isAjax = false
                console.log(err)
            })
        },
        /**
         * 查看订单接口
         * @params {String} sn 订单号
         */
        handleOrderShow (sn) {
            if (this.isAjax) return
            this.isAjax = true
            order.show({
                sn: sn
            }).then(res => {
                this.isAjax = false
                // status 状态修改
                if (res.status != 1) return this.$toast(res.message)
                let data = res.data
                if (data.status == 1) return this.$router.push({name: 'Answer', query: {sn: data.sn }})
                if (data.status == 0) return this.$router.push({ name: 'Payment', query: { sn: data.sn }})
            }).catch(err => {
                this.isAjax = false
                console.log(err)
            })
        },

        /**
         * 滚动到最底部
         * @param {String} id       html id
         * @param {Nunber} index    tab 下标
         * @param {Boolean} bool    是否滚动
         */
        handleScrollTo (id, index, bool = true) {
            this.tabId = index
            this.$nextTick(() => {
                let options = { top: document.getElementById(id).offsetTop - document.getElementById('sticky').clientHeight, left: 0 };
                if (bool) options.behavior = 'smooth'
                window.scroll(options);
            })
        }
    },

    data() {
        return {
            isAjax: false,
            loading: true,
            isQrcode: false,        // 展示客服二维码
            qrcodeUrl: '',          // 客服二维码
            isTicket: false,        // 展示公众号二维码
            ticketUrl: '',          // 公众号二维码
            item: {},               // 详情数据
            commentItems: [],       // 评论数据

            tabId: 0,
            tabItems: [
                { name: '测评介绍', id: 'content' },
                { name: '用户评价', id: 'comment' },
                { name: '推荐更多', id: 'recommend' }
            ]
        }
    }

}